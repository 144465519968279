<template>
  <div class="card">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>分类管理</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search">
      <span>分类名称：</span>
      <el-input v-model="list.cardName" placeholder="请输入分类名称"></el-input>
      <span>万旅网显示:</span>
      <el-select clearable v-model="list.wlShow" placeholder="请选择">
        <el-option
          v-for="item in optionsShow"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-button type="primary" @click="getCard()">查找</el-button>
      <el-button
        type="primary"
        @click="onAdd"
        v-if="$store.state.powerList.indexOf('card:info:list:add') !== -1"
        >添加</el-button
      >
    </div>
    <el-table
      :data="tableData"
      style="width: 100%"
      border
      @selection-change="onSelectionChange"
    >
      <el-table-column
        align="center"
        type="selection"
        width="50"
      ></el-table-column>
      <el-table-column
        v-if="$store.state.powerList.indexOf('card:info:list:update') !== -1"
        header-align="center"
        align="center"
        prop="dept"
        label="排序"
      >
        <template slot-scope="{ row }">
          <el-input
            class="input-new-tag"
            v-if="row.inputVisible"
            v-model="row.dept"
            ref="saveTagInput"
            size="small"
            @input="onInput"
            @keyup.enter.native="handleInputConfirm(row)"
            @blur="handleInputConfirm(row)"
          >
          </el-input>
          <el-button
            v-else
            class="button-new-tag"
            size="small"
            @click="onChangeSort(row)"
            >{{ row.dept }}</el-button
          >
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="cardId"
        label="卡片ID"
        width="width"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="cateName"
        label="所属分类"
        width="width"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="cardName"
        label="卡片名称"
        width="width"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="cardImg"
        label="卡片图片"
        width="width"
      >
        <template slot-scope="{ row }">
          <el-image
            style="width: 80px; height: 80px"
            :src="row.cardImg"
            :preview-src-list="[row.cardImg]"
          ></el-image>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="label"
        label="标签"
        width="width"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="price"
        label="价格"
        width="width"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="strStatus"
        label="状态"
        width="width"
      >
      </el-table-column>
      <el-table-column align="center" prop="unsubscribe" label="是否退订">
      </el-table-column>
      <el-table-column align="center" prop="isShow" label="是否显示">
      </el-table-column>
      <el-table-column align="center" prop="wlShow" label="万旅网显示">
      </el-table-column>
      <el-table-column align="center" label="操作" width="350">
        <template slot-scope="{ row }">
          <el-button
            type="primary"
            size="mini"
            @click="onDelRow(row.cardId, 1)"
            v-if="
              row.status === 2 &&
              $store.state.powerList.indexOf('card:info:list:update') !== -1
            "
            >上架</el-button
          >
          <el-button
            type="primary"
            size="mini"
            @click="onDelRow(row.cardId, 2)"
            v-if="
              row.status === 1 &&
              $store.state.powerList.indexOf('card:info:list:update') !== -1
            "
            >下架</el-button
          >
          <el-button
            type="primary"
            size="mini"
            v-if="
              $store.state.powerList.indexOf('card:info:list:select') !== -1
            "
            @click="onEdit(row.cardId)"
            >编辑</el-button
          >
          <el-button
            type="primary"
            size="mini"
            v-if="
              $store.state.powerList.indexOf('card:info:list:update') !== -1
            "
            @click="onDelRow(row.cardId, 0)"
            >删除</el-button
          >
          <el-button type="primary" size="mini" @click="creatCode(row.cardId)"
            >生成小程序码</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="btn">
      <el-button
        type="primary"
        @click="onChangeAllStatusShow(1)"
        v-if="$store.state.powerList.indexOf('card:info:list:wlShow') !== -1"
        >万旅网批量显示</el-button
      >
      <el-button
        type="primary"
        @click="onChangeAllStatusShow(0)"
        v-if="$store.state.powerList.indexOf('card:info:list:wlShow') !== -1"
        >万旅网批量隐藏</el-button
      >
    </div>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="list.currentPage"
      :page-sizes="[5, 10, 15, 20]"
      :page-size="list.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pagination.total"
    >
    </el-pagination>
    <el-dialog title="提示" :visible.sync="codeVisible" width="30%">
      <div class="inner_top">
        <el-input
          style="width: 300px"
          v-model.trim="QRcodeSize"
          placeholder="请输入生成的二维码大小"
        ></el-input>

        <el-button type="primary" @click="generateQRcode">生成</el-button>
      </div>
      <div style="color: red">推荐宽度：280px-1280px</div>
      <el-image v-show="imgUrl" fit="fill" :src="imgUrl"></el-image>
      <span slot="footer" class="dialog-footer">
        <span>右键点击图片，点击另存为保存图片</span>
        <el-button @click="codeVisible = false">取 消</el-button>
        <el-button type="primary" @click="codeVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  cardList,
  addOrEditCategory,
  changeCardSort,
  changeCardStatus,
  wanlvCardShow
} from "../../api/wanlvCard";
import { createEr5 } from "../../api/erCode";
import { sendFile } from "../../api/sendFile";
export default {
  name: "card",
  data() {
    return {
      QRcodeSize: "",
      codeVisible: false,
      cardId: "",
      imgUrl: "",
      tableData: [],
      list: {
        pageSize: 5,
        currentPage: 1,
        cardName: "",
        wlShow: -1,
      },
      pagination: {},
      ids: "",
      optionsShow: [
        {
          value: -1,
          label: "全部",
        },
        {
          value: 1,
          label: "显示",
        },
        {
          value: 0,
          label: "隐藏",
        },
      ],
    };
  },
  created() {
    this.getCard();
  },
  methods: {
    onChangeAllStatusShow(status){
      if(!this.ids){
        return this.$message({
          type: "warning",
          message: "请选择要修改的内容!",
        });
      }
      // 是否修改
      this.$confirm("是否要批量修改选中万旅卡的状态?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          console.log(this.ids)
          const { data } = await wanlvCardShow({
            cardId: this.ids,
            wlShow:status,
          });
          if (data.code === 0) {
            this.getCard();
            this.$message({
              type: "success",
              message: data.msg,
            });
          } else {
            this.$message({
              type: "error",
              message: data.msg,
            });
          }
        })
        .catch(() => {
          
        });

    },
    onSelectionChange(selection) {
      var arr = [];
      selection.map((item) => {
        arr.push(item.cardId);
      });
      this.ids = arr.join(",");
    },
    creatCode(id) {
      this.cardId = id;
      this.codeVisible = true;
      this.imgUrl = "";
    },
    async generateQRcode() {
      this.imgUrl = "";
      if (!this.QRcodeSize) {
        this.$message({ message: "请输入宽度", type: "warning" });
      } else {
        const { data } = await createEr5({
          cardId: this.cardId,
          widthSize: this.QRcodeSize,
        });
        this.imgUrl = data.msg;
        console.log(this.imgUrl);
      }
    },
    //删除
    onDelRow(cardId, status) {
      this.$confirm("是否设置这条数据", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await changeCardStatus({
            cardId,
            status,
          });
          if (data.code === 0) {
            this.$message({
              message: data.msg,
              type: "success",
            });
            this.getCard();
          } else {
            this.$message({
              message: data.msg,
              type: "error",
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    onEdit(id) {
      this.$router.push(`/addCard/${id}`);
    },
    onDiaLog() {
      this.addOrEdit.cateImg = null;
      this.addOrEdit.cateId = null;
      this.addOrEdit.cateName = null;
    },
    onAdd() {
      this.$router.push(`/addCard/${0}`);
    },
    async getCard() {
      const { data } = await cardList(this.list);
      if (data.code === 0) {
        data.list.map((item) => {
          item.inputVisible = false;
        });
        this.tableData = data.list;
        this.pagination = data.pagination;
      } else {
        this.$message.error(data.msg);
      }
    },
    onChangeSort(row) {
      row.inputVisible = true;
    },
    async handleInputConfirm(row) {
      const { data } = await changeCardSort({
        cardId: row.cardId,
        dept: Number(row.dept),
      });
      if (data.code !== 0) {
        this.$message({
          message: data.msg,
          type: "error",
        });
      } else {
        this.$message({
          message: data.msg,
          type: "success",
        });
      }
      row.inputVisible = false;
    },

    onInput(value) {
      var reg = /^[0-9]*[1-9][0-9]*$/;
      var reg2 = /^-[0-9]*[1-9][0-9]*$/;
      if (!reg.test(value) && !reg2.test(value)) {
        return this.$message({
          type: "warning",
          message: "请输入整数!",
        });
      }
    },
    sendAvatar() {
      this.$refs.fileRef.click();
    },
    async onFileChange(event) {
      // 根据这个 <input> 获取文件的 HTML5 js 对象
      var files = event.target.files;
      if (files && files.length > 0) {
        // 获取目前上传的文件
        var file = files[0];
        var formData = new FormData();
        formData.append("file", file);
        const { data } = await sendFile(formData);

        this.addOrEdit.cateImg = data.data[0].src;
      }
    },
    handleSizeChange(num) {
      this.list.pageSize = num;
      this.getCard();
    },
    handleCurrentChange(num) {
      this.list.currentPage = num;
      this.getCard();
    },
  },
};
</script>
<style lang="less" scoped>
.btn {
    float: left;
    margin-top: 50px;
  }
.card {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }
  .search {
    margin-top: 50px;
    .el-select {
      margin: 0 20px;
    }
    .select {
      margin: 0 10px;
    }
    .el-input {
      width: 200px;
      margin: 0 20px;
    }
  }
  .el-table {
    margin-top: 50px;
  }
  .el-pagination {
    float: right;
    margin-top: 50px;
  }
  /deep/.el-form {
    .el-form-item {
      .el-form-item__content {
        .elbtn {
          margin-left: 10px;
          background-color: unset;
          width: 110px;
          height: 110px;
          text-align: center;
          line-height: 110px;
          color: #000;
          border: 1px solid #ccc;
        }
        display: flex;
        .img {
          position: relative;
          margin: 0 20px;
          height: 100px;
          width: 100px;
          img {
            height: 100px;
            width: 100px;
            border: 1px solid #c0ccda;
          }
          div {
            position: absolute;
            top: 0;
            right: 5px;
            height: 10px;
            width: 10px;
            z-index: 100;
          }
        }
        .addImg {
          display: inline-block;
          width: 100px;
          height: 100px;
          border: 1px dashed #c0ccda;
          text-align: center;
          line-height: 100px;
          cursor: pointer;
          i {
            width: 50px;
            height: 50px;
            font-size: 20px;
            color: #c0ccda;
          }
        }
        span {
          margin-left: 30px;
          color: red;
        }
      }
    }
  }
}
</style>